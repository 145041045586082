import React from "react";
import {not_validate_value} from "../App";
import Grid from "@material-ui/core/Grid";
import WarningIcon from '@material-ui/icons/Warning';

export const WarningBox = props =>{
        if (!not_validate_value.includes(props.massage)) {
            return(
        <Grid item xs={12} className="waring-box">
            <WarningIcon className="mr-2"/>
            {props.massage}
        </Grid>
    )}
        return "";
}
