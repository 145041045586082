import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Fade from "@material-ui/core/Fade";
import "../assets/scss/Static.scss";

export const CustomModal = props =>{
    return(
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            className="overflow-auto"
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open} className='form-modal p-2'>
                {props.children}
            </Fade>
        </Modal>
    );
}