import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {AlertContext, ConfirmationContext} from "./store/use_context";
import {CheckTokenAndEntity} from "./auth/CheckTokenAndEntity";
import {NotFoundOne} from "./pages/notfound/NotFoundOne";
import {CustomAlertModal} from "./components/CustomAlertModal";
import {ConfirmationModal} from "./components/ConfirmationModal";
import {BookClasses} from "./pages/book_classes/BookClasses";
import {EntityRoute} from "./routes/EntityRoute";
import ReactGA from 'react-ga';

export const not_validate_value = [undefined, null, ""];

function App() {
    ReactGA.initialize('UA-179691127-1');

    const [alert_msg, setAlert] = useState("")
    const [confirm_data, setConfirmData] = useState({})

    return (
      <BrowserRouter>
        <Switch>
            <AlertContext.Provider value={{alert_msg, setAlert}}>
                <ConfirmationContext.Provider value={{confirm_data, setConfirmData}}>
                <CustomAlertModal/>
                    <ConfirmationModal/>
                    <Switch>
                    <Route path='/book-classes' render={routeProps =><CheckTokenAndEntity {...routeProps} page={BookClasses}/>}/>
                    <Route path='/:sub_directory' component={EntityRoute}/>
                    </Switch>
                </ConfirmationContext.Provider>
            </AlertContext.Provider>
            <Route path='*' component={NotFoundOne}/>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
