import React from "react";
import MuiPhoneInput from "material-ui-phone-number";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";

export const PhoneNumberField = props => {

    const handlePhoneFieldChange = inputValue => {
        props.onChangeHandle(props.name, inputValue);
    };


    return (
            <MuiPhoneInput
                disabled={props.disabled}
                fullWidth
                disableAreaCodes={true}
                error={props.error}
                helperText={props.helper_text}
                style={{direction:"ltr"}}
                variant="outlined"
                label={<label> {props.label}<span className='text-danger'>{props.required ? "*" : ""}</span></label>}
                defaultCountry="kw"
                onChange={handlePhoneFieldChange}
                value={props.value || ""}

            />
    )
};