import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {BaseBackEndURL} from "../urls";
import {getRefreshToken, login} from "./auth_functions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {not_validate_value} from "../App";
import {Hide} from "../components/Hide";
import {Loading} from "../components/Loading";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Rekords
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#3f51b5",
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#3f51b5",
        color: 'white'
    },
}));

export const Login = props => {
    const classes = useStyles();
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    const [check_access_token_loading, setCheckAccessTokenLoading] = useState(true);
    let entity = localStorage.getItem('entity_id')
    let refresh_token = localStorage.getItem('refresh_token')

    useEffect(() => {
        const checkToken = async () => {
            await setCheckAccessTokenLoading(true);
            if (!not_validate_value.includes(refresh_token)){
                let response = await getRefreshToken(refresh_token);
                if (response.status) {
                    window.location.href = `/book-classes/?token=${response.data.access_token}&entity=${entity}`;
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('refresh_token', response.data.refresh_token);
                }else{
                    await setCheckAccessTokenLoading(false);
                }
            }else{
                await setCheckAccessTokenLoading(false);
            }
        }

        checkToken();
    },[])

    const handleFieldChange = (name, value) => {
        setData({...data, [name]: value})
    }

    const onSubmit = async e => {
        e.preventDefault()
        if (!is_loading) {
            await setIsLoading(true);
            const {username, password} = data;
            setError(false);

            let response = await login(username, password);
            if (response.status) {
                window.location.href = `/book-classes/?token=${response.data.access_token}&entity=${entity}&web_view=true`;
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
            } else {
                setError(true)
            }
            await setIsLoading(false);
        }

    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>

            <Hide hide={!check_access_token_loading}>
                <Loading/>
            </Hide>

            <Hide hide={check_access_token_loading}>
            <div className={classes.paper}>

                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Login
                </Typography>


                <form className={classes.form} onSubmit={onSubmit} noValidate>
                    <label
                        className="text-danger text-center">{error && "Invalid login information. Please try again."}</label>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={data.username}
                        onChange={event => handleFieldChange("username", event.target.value)}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="password"
                        value={data.password}
                        onChange={event => handleFieldChange("password", event.target.value)}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                    >
                        {is_loading ? <CircularProgress className="text-white" size={25}/> : "Login"}
                    </Button>

                    <Grid container>
                        <Grid item xs className="mt-3">
                            <Link href={`${BaseBackEndURL}forgot-password`} variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>

                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Hide>

</Container>
    );
}