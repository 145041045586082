import React, {useEffect, useState} from "react";
import queryString from 'query-string'
import {Loading} from "../components/Loading";
import {createAxiosInstance, httpRequestWithAuth} from "./axios_instance";
import {Hide} from "../components/Hide";
import {BaseEntityGetSubDirectoryURL} from "../urls";
import {not_validate_value} from "../App";
import {UserActiveCodeContext} from "../store/use_context";
import {Switch} from "react-router-dom";
import {ActivateUserWithClient} from "../components/ActivateUserWithClient";

export const CheckTokenAndEntity = ({page: Page, ...props}) => {
    const [is_loading, setIsLoading] = useState(true);
    const [activate_data, setActivate_data] = useState({open:false});
    const [entity_data, setEntityData] = useState({});
    const [is_web_view, setIsWebView] = useState(false);

    useEffect(() => {
        const checkData = async () => {
            await setIsLoading(true);
            let url_params = await queryString.parse(props.location.search) || {}
            let token = url_params.token
            let entity = url_params.entity
            setIsWebView(url_params.web_view === 'true')
            await createAxiosInstance(token)
            let get_sub_directory = await httpRequestWithAuth(`${BaseEntityGetSubDirectoryURL}${entity}/`, "GET")
            if (get_sub_directory.status){
                await setEntityData(get_sub_directory.data)
            }
            await setIsLoading(false);
        }

        checkData();
    }, [])


    return (
        <>
            <Hide hide={!is_loading}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading}>

                {/*<Hide hide={!not_validate_value.includes(entity_data.id)}>*/}
                {/*    <NotFoundOne/>*/}
                {/*</Hide>*/}

                <Hide hide={not_validate_value.includes(entity_data.id)}>
                    <UserActiveCodeContext.Provider value={{activate_data, setActivate_data}}>

                            <Hide hide={!activate_data.open}>
                                <ActivateUserWithClient/>
                            </Hide>

                            <Switch>
                                <Page sub_directory={entity_data.sub_directory} entity={entity_data.id} is_web_view={is_web_view}/>
                            </Switch>
                    </UserActiveCodeContext.Provider>
                </Hide>

            </Hide>
        </>
    );
}
