import React, {useEffect, useState} from "react";
import {Route, Switch, useParams} from "react-router-dom";
import {Redirect} from "react-router";
import {httpRequestWithoutAuth} from "../auth/axios_instance";
import {BaseEntityGetBySubDirectoryURL} from "../urls";
import {Loading} from "../components/Loading";
import {Hide} from "../components/Hide";
import {Login} from "../auth/Login";
import {NotFoundOne} from "../pages/notfound/NotFoundOne";


export const EntityRoute = props => {
    let {sub_directory} = useParams();
    const [is_loading, setIsLoading] = useState(true)
    const [sub_directory_error, setSubDirectoryError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {

            let response = await httpRequestWithoutAuth(BaseEntityGetBySubDirectoryURL, "GET", null, {sub_directory})
            if (response.status) {
                localStorage.setItem('entity_id', response.data.id)
                localStorage.setItem('sub_directory', sub_directory)
            }else{
                setSubDirectoryError(true)
            }

            setIsLoading(false)
        }

        fetchData()
    }, [])

    return (
        <>
            <Hide hide={!sub_directory_error}>
                <NotFoundOne/>
            </Hide>

            <Hide hide={!is_loading || sub_directory_error}>
                <Loading/>
            </Hide>

            <Hide hide={is_loading || sub_directory_error}>
                <Switch>
                        <Route path="/:sub_directory/login" component={Login}/>
                        <Route exact path="/:sub_directory" render={() => <Redirect to={`${sub_directory}/login`}/>}/>
                        <Route exact path="/:sub_directory/" render={() => <Redirect to={`/${sub_directory}/login`}/>}/>
                        {/*<Route path='*' component={NotFoundOne}/>*/}
                </Switch>
            </Hide>
        </>
    )
}