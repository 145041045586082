import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const FormHeader = props => {
    return (

        <Grid item xs={12} className="d-inline-flex justify-content-between">
            <Typography variant="h6" className='p-1'>
                {props.title}
            </Typography>


            <Tooltip title="Close">
                <IconButton size="small" className="mt-1 text-start" color='inherit'
                            onClick={props.handleClose}>
                    <CloseIcon/>
                </IconButton>
            </Tooltip>
        </Grid>
    );
};