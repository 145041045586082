import React, {useContext, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import {
    BaseClassesClientFilterDataURL,
    BaseClassRegisterURL,
    BaseClassUnRegisterURL,
    BaseClientActiveMembershipURL,
    BaseClientClassesURL
} from "../../urls";
import {httpRequestWithAuth} from "../../auth/axios_instance";
import moment from "moment";
import {AlertContext, ConfirmationContext, UserActiveCodeContext} from "../../store/use_context";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {NotFoundOne} from "../notfound/NotFoundOne";
import {Hide} from "../../components/Hide";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactGA from "react-ga";
import {Grid, Tab} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {ErrorBox} from "../../components/ErrorBox";
import {SelectField} from "../../components/SelectField";
import {CustomTabs} from "../../components/CustomTabs";
import {WarningBox} from "../../components/WarningBox";
import LinearProgress from '@material-ui/core/LinearProgress';

export const BookClasses = props => {
    useEffect(() => {
        ReactGA.pageview('/book-classes');
    }, []);

    const [error_massage, setErrorMassage] = useState("");
    const [data, setData] = useState([]);
    const [view_register_on_dates, setViewRegisterOnDates] = useState([]);
    const [dates, setDates] = useState([]);
    const [locations, setLocations] = useState([]);
    const [services, setServices] = useState([]);
    const [current_locations, setCurrentLocations] = useState(0);
    const [current_service, setCurrentService] = useState(0);
    const [current_date, setCurrentDate] = useState(0);
    const [is_loading, setIsLoading] = useState(false);
    const [entity_error, setEntityError] = useState(false);
    const {setAlert} = useContext(AlertContext);
    const {setActivate_data} = useContext(UserActiveCodeContext);
    const [reload_classes, setReloadClasses] = useState(false);
    const [on_date_massage, setOnDateMassage] = useState("");
    const [membership_session_details, setMembershipSessionDetails] = useState({});
    const {setConfirmData} = useContext(ConfirmationContext);

    const columns = [
        {
            render: rowData => {
                return (
                    <>
                        <Typography gutterBottom variant="subtitle1" className="font-weight-bold" component="p">
                            {viewTime(rowData.start_time, rowData.end_time)}
                            {/*{ rowData.is_registered && (<Chip className="ml-2 primary-bg text-white" size="small" label="Registered"/>)}*/}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" className="font-weight-bold" component="p">
                            {`${rowData.name}`}
                        </Typography>

                        <Typography variant="body1" color="textSecondary" component="p">
                            {`${rowData.staff_name}`}
                        </Typography>
                    </>
                )
            }, cellStyle: {width: "100%"}
        },
        {
            render: rowData => (
                <div className="w-100 text-right">
                    <Hide
                        hide={!rowData.is_registered && (rowData.is_full || rowData.is_locked || rowData.is_started || view_register_on_dates[current_date])}>

                        <Hide hide={rowData.is_registered}>
                            <Button variant="outlined"
                                    className={rowData.is_registered ? 'text-danger' : 'primary-color'}
                                    onClick={() => onBtnListClicked(!rowData.is_registered, rowData)}>
                                {rowData.is_registered ? "unregister" : "register"}
                            </Button>
                        </Hide>

                        <Hide hide={!rowData.is_registered}>
                            <div className="text-center">
                                <Button variant="outlined" color="primary" className="primary-color">
                                    Registered
                                </Button>

                                <Hide hide={rowData.is_started}>
                                    <Button
                                        className='text-danger mt-1'
                                        onClick={() => onBtnListClicked(!rowData.is_registered, rowData)}>
                                        Cancel
                                    </Button>
                                </Hide>

                            </div>
                        </Hide>
                    </Hide>


                    <Hide hide={!rowData.is_full || rowData.is_registered}>
                        <Typography variant="body1" component="p" className="text-danger">
                            Full
                        </Typography>
                    </Hide>

                    <Hide hide={rowData.is_registered || (rowData.is_full || !rowData.is_locked)}>
                        <Typography variant="body1" component="p" className="text-danger">
                            Locked
                        </Typography>
                    </Hide>

                </div>
            )
        }
    ];

    useEffect(()=>{
        const fetchClasses = async ()=>{
            checkMemberships();

            if (dates.length > 0 && services.length > 0 && locations.length > 0){
                await setIsLoading(true);
                await listClasses(dates[current_date], services[current_service].service_id, locations[current_locations].location__id);
                await setIsLoading(false);
            }
        }

        fetchClasses();
    },[current_date, current_service, current_locations, reload_classes])


    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);
            await getFilterData();
            await setIsLoading(false);
        }

        fetchData();
    }, []);


    const checkMemberships = async () => {
        let response = await httpRequestWithAuth(BaseClientActiveMembershipURL, "GET", null, {entity: props.entity})
        if (!response.status) {
            setErrorMassage(response.msg);
            return false
        }else{
            setErrorMassage("");
        }

        return true
    }

    const viewTime = (start_time, end_time) => {
        start_time = moment('1995-06-09 ' + start_time).format("hh:mm A")
        end_time = moment('1995-06-09 ' + end_time).format("hh:mm A")

        return (
            <>
                    {start_time.slice(0, 5)} <sup className="font-weight-normal">{start_time.slice(6)}</sup>
                    {`   -   ${end_time.slice(0, 5)}`} <sup className="font-weight-normal">{end_time.slice(6)}</sup>
            </>
        )
    }



    const setClasses = async data => {
        let temp_register_dates = [];

        await data.map(ClassSession => {
            let date_index = dates.indexOf(ClassSession.date);
            if (date_index < 0) {
                temp_register_dates.push(ClassSession.is_registered && ClassSession.is_started)
            }
        })


        await setData(data);
        await setViewRegisterOnDates(temp_register_dates);
    }

    const getFilterData= async () =>{
        let response = await httpRequestWithAuth(BaseClassesClientFilterDataURL, "GET", null, {entity: props.entity})
        if (response.status){
            if(response.data.services.length>0 && response.data.dates.length>0 && response.data.locations.length>0){
                await listClasses(response.data.dates[0],
                    response.data.services[0].service_id,
                    response.data.locations[0].location__id, true);
            }

            await setDates(response.data.dates);
            await setServices(response.data.services);
            await setLocations(response.data.locations);
            await checkMemberships();
        }else{
            if (response.error_feedback.client_error) {
                await setActivate_data({
                    open: true,
                    action: ()=> getFilterData()
                })
            } else if (response.error_feedback.entity_error) {
                await setEntityError(true)
            } else {
                await setAlert(response.msg)
            }
        }
    }

    const listClasses = async (date, service, location, ) => {
        let response = await httpRequestWithAuth(BaseClientClassesURL, "GET", null, {entity: props.entity, date, service, location})

        if (response.status) {
            await setClasses(response.data.details);
            await setOnDateMassage(response.data.massage);
            await setMembershipSessionDetails({...response.data.membership_session_details, value_percentage: 100.0 * response.data.membership_session_details.membership_attended_sessions_number/response.data.membership_session_details.membership_session_number});

        } else {
            if (response.error_feedback.client_error) {
                await setActivate_data({
                    open: true,
                    action: ()=> setReloadClasses(!reload_classes)
                })
            } else if (response.error_feedback.entity_error) {
                await setEntityError(true)
            } else {
                await setAlert(response.msg)
            }
        }
    }

    const handleUnregisterAction = async (class_data) => {
        await setIsLoading(true);
        let response = await httpRequestWithAuth(BaseClassUnRegisterURL, "POST", class_data)
        if (response.status) {
            await setReloadClasses(!reload_classes);
        } else {
            await setAlert(response.msg);
            await setIsLoading(false);
        }
    };


    const onBtnListClicked = async (registration, class_session, confirm_finish_freeze=false) => {
        let class_data = {'class': class_session.id, 'entity': props.entity, class_date: class_session.date, confirm_finish_freeze}
        let response;
        if (registration) {
            await setIsLoading(true);

            response = await httpRequestWithAuth(BaseClassRegisterURL, "POST", class_data)

            if (response.status) {
                // await updateClassStatus(class_data, true);
               await setReloadClasses(!reload_classes);
               await setAlert(response.data.massage);
            } else {
                if (response.error_feedback.membership_freeze_error){
                    setConfirmData({
                        msg: response.msg,
                        action: () => onBtnListClicked(registration, class_session, true)
                    })
                }else{
                    await setAlert(response.msg);
                    await setIsLoading(false);
                }
            }
        } else {
            setConfirmData({
                msg: (
                    <>
                        {"Are you sure to unregister from class "}
                        {viewTime(class_session.start_time, class_session.end_time)}
                    </>
                ),
                action: () => handleUnregisterAction(class_data)
            })
        }
    }

    const handleLocationChange = (name, value) => {
        value = parseInt(value);
        setCurrentLocations(value);
    }

    const handleServiceChange = (name, value) => {
        value = parseInt(value);
        setCurrentService(value);
    };

    const nextDate = () => {
        if (current_date < dates.length - 1) {
            setCurrentDate(current_date + 1)
        }
    }

    const backDate = () => {
        if (current_date > 0) {
            setCurrentDate(current_date - 1)
        }
    }


    const handleLogOut = async () => {
        await localStorage.clear();
        window.location.href = `/${props.sub_directory}/login`;
    }

    return (
        <>
            <Hide hide={!entity_error}>
                <NotFoundOne/>
            </Hide>

            <Hide hide={entity_error}>
                <Container component="main" maxWidth="lg" className="pt-5  pb-5">
                    <CssBaseline/>

                    <Hide hide={!props.is_web_view}>
                        <Grid item xs={12} className="text-right mb-2">
                            <Button
                                size="large"
                                startIcon={<ExitToAppIcon/>}
                                onClick={handleLogOut}
                            >
                                Logout
                            </Button>
                        </Grid>
                    </Hide>

                    <Grid item xs={12}>
                        <ErrorBox errorMsg={error_massage}/>
                    </Grid>

                    <Paper className="paper">
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h5" className="text-center mb-1">
                                        Classes
                                    </Typography>
                                </Grid>


                                <Hide hide={locations.length < 2}>
                                        <Grid item xs={12}>
                                            <SelectField
                                                label="Location"
                                                name="location"
                                                value={current_locations}
                                                onChangeHandle={handleLocationChange}
                                                options={locations}
                                                label_key="location__name"
                                            />
                                        </Grid>
                                </Hide>


                                <Hide hide={services.length < 1}>
                                <Grid item xs={12}>
                                    <CustomTabs
                                        currentTab={current_service}
                                        handleTabsChange={handleServiceChange}
                                    >

                                        {services.map((Service, Index) => (
                                            <Tab label={Service.service_name} id={Index} key={Index}/>
                                        ))}

                                    </CustomTabs>
                                </Grid>
                                </Hide>
                            </Grid>

                            <Hide hide={dates.length < 1}>
                                <Toolbar>
                                    <Typography variant="body1" color="textSecondary" component="p">
                                        {`${moment(dates[current_date]).format("dddd, DD MMM YYYY")}`}
                                    </Typography>

                                    <div className="mr-auto"/>

                                    <Hide hide={current_date === 0}>
                                        <IconButton onClick={backDate}>
                                            <ArrowBackIosIcon/>
                                        </IconButton>
                                    </Hide>

                                    <Hide hide={current_date === dates.length - 1}>
                                        <IconButton onClick={nextDate}>
                                            <ArrowForwardIosIcon/>
                                        </IconButton>
                                    </Hide>

                                </Toolbar>
                            </Hide>


                        <Hide hide={!membership_session_details.is_session_membership}>
                            <div className="text-right">
                                <span className="mr-3">Attended classes: {`${membership_session_details.membership_attended_sessions_number} of ${membership_session_details.membership_session_number}`}</span>
                        <LinearProgress variant="determinate" className="mt-3 mb-3" value={membership_session_details.value_percentage} style={{height:'20px', borderRadius:'5px'}} />
                            </div>
                        </Hide>

                            <WarningBox massage={on_date_massage}/>

                        <MaterialTable
                            title="Class Sessions"
                            isLoading={is_loading}
                            style={{
                                boxShadow: "none",
                                padding: 0,
                            }}

                            columns={columns}
                            data={data}

                            options={{
                                actionsColumnIndex: -1,
                                paging: false,
                                search: false,
                                showTitle: false,
                                header: false,
                                toolbar: false,
                                rowStyle: rowData => ({
                                    // backgroundColor: (rowData.is_registered) ? 'rgba(144,238,144,0.2)': ""
                                    // backgroundColor: (rowData.is_registered) ? 'rgba(63, 81, 181,0.2)': ""
                                })
                            }}
                        />

                    </Paper>
                </Container>
            </Hide>
        </>
    )
}

