import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

export const SelectField = props => {
    return (
        <FormControl variant="outlined" fullWidth
                     disabled={props.viewOnly}
                     className={props.disabled ? "disabled-field" : ""}
                     error={props.error}>

            <InputLabel>{props.label}<span className="text-danger">{props.required ? "*" : ""}</span></InputLabel>

            <Select
                value={props.value}
                label={props.label}

                onChange={e => props.onChangeHandle(e.target.name, e.target.value)}
                inputProps={{
                    name: props.name,
                }}
                className={"text-start"}
            >

                {props.options.map((Option, Index) => {
                    return (
                        <MenuItem key={Index} value={Index}> {props.label_key?Option[props.label_key]:Option}</MenuItem>
                    );
                })}

            </Select>
            <FormHelperText>{props.helper_text}</FormHelperText>
        </FormControl>
    )
};
