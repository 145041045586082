import React, {useContext, useState} from "react";
import {CustomModal} from "./CustomModal";
import {UserActiveCodeContext} from "../store/use_context";
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {ErrorBox} from "./ErrorBox";
import {makeStyles} from '@material-ui/core/styles';
import {httpRequestWithAuth} from "../auth/axios_instance";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {BaseActivateUserURL} from "../urls";
import {not_validate_value} from "../App";
import Button from "@material-ui/core/Button";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {PhoneNumberField} from "./PhoneNumberField";
import {Loading} from "./Loading";
import {Hide} from "./Hide";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    paper: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#3f51b5',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    startAdornment: {
        color: '#3f51b5',
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        fontSize: "20px",
        fontWeight: 'bolder'
    },
    endAdornment: {
        backgroundColor: '#3f51b5',
        color: "white",
        '&:hover': {
            background: 'darkblue',
        },
    }
}));


export const ActivateUserWithClient = props => {
    const {activate_data, setActivate_data} = useContext(UserActiveCodeContext)
    const classes = useStyles();
    const [data, setData] = useState({
        way: 'social_id_and_mobile'
    });
    const [error, setError] = useState({})
    const [is_loading, setIsLoading] = useState(false)

    const onFormSubmit = async e => {
        e.preventDefault()
        let temp_data = {...data}
        await setIsLoading(true);

        Object.keys(temp_data).map(Key => {
            if (not_validate_value.includes(temp_data[Key])) {
                delete temp_data[Key]
            }
        })

        let response = await httpRequestWithAuth(BaseActivateUserURL, "POST", temp_data)
        if (response.status) {
            await activate_data.action();
            await setActivate_data({open: false});
        } else {
            await setError(response.error_feedback)
        }

        await setIsLoading(false);
    }

    const onFieldChange = (name, value) => {
        setData({...data, [name]: value})
        setError({})
    }

    return (
        <CustomModal
            open={activate_data.open}
        >

            <Container component="main" maxWidth="sm" className={classes.root}>
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <AccountCircleIcon className="w-100 h-100"/>
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        Activate your profile
                    </Typography>

                    <Hide hide={!is_loading}>
                        <Loading/>
                    </Hide>

                    <form onSubmit={onFormSubmit} className={classes.form}>
                        <Hide hide={is_loading}>
                            {/*<RadioGroup name="way" value={data.way}*/}
                            {/*            onChange={e => onFieldChange('way', e.target.value)}>*/}


                            {/*<FormControlLabel value="social_id_and_mobile" control={<Radio/>}*/}
                            {/*                      label="By Civil ID & Mobile"/>*/}
                                <TextField
                                    className="mb-3"
                                    disabled={data.way !== 'social_id_and_mobile'}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Civil ID"
                                    name="social_id"
                                    error={error.social_id !== undefined}
                                    helperText={error.social_id}
                                    autoComplete="off"
                                    value={data.social_id}
                                    onChange={e => onFieldChange(e.target.name, e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <RecentActorsIcon className="mr-3"/>
                                        ),
                                    }}
                                />

                                <PhoneNumberField
                                    disabled={data.way !== 'social_id_and_mobile'}
                                    value={data.mobile}
                                    onChangeHandle={onFieldChange}
                                    name="mobile"
                                    label="Mobile"
                                    helper_text={error.mobile}
                                    error={error.mobile !== undefined}
                                />


                                {/*<FormControlLabel value="activation_code" control={<Radio/>}*/}
                                {/*                  label="By Activation Code" className="mt-4"/>*/}
                                {/*<TextField*/}
                                {/*    disabled={data.way !== 'activation_code'}*/}
                                {/*    variant="outlined"*/}
                                {/*    margin="normal"*/}
                                {/*    fullWidth*/}
                                {/*    label="Activation Code"*/}
                                {/*    name="active_code"*/}
                                {/*    error={error.active_code !== undefined}*/}
                                {/*    helperText={error.active_code}*/}
                                {/*    autoComplete="off"*/}
                                {/*    value={data.active_code}*/}
                                {/*    onChange={e => onFieldChange(e.target.name, e.target.value)}*/}
                                {/*    InputProps={{*/}
                                {/*        startAdornment: (<label className={classes.startAdornment}>#</label>),*/}
                                {/*        endAdornment: (*/}
                                {/*            <Tooltip title="View">*/}
                                {/*                <IconButton type="submit" size="small"*/}
                                {/*                            className={classes.endAdornment}*/}
                                {/*                            disabled={data.way !== 'activation_code'}*/}
                                {/*                            onClick={props.action}>*/}
                                {/*                    <ArrowForwardIcon/>*/}
                                {/*                </IconButton>*/}
                                {/*            </Tooltip>*/}
                                {/*        ),*/}
                                {/*    }}*/}
                                {/*/>*/}
                            {/**/}
                            {/*</RadioGroup>*/}

                            <ErrorBox errorMsg={error.non_field_errors}/>

                            <Button
                                type="submit"
                                variant="contained"
                                className={`mt-3 ${classes.endAdornment}`}
                                fullWidth
                            >
                                Activate
                            </Button>
                        </Hide>
                    </form>

                </div>


            </Container>


        </CustomModal>
    )
}