import {httpRequestWithoutAuth} from "./axios_instance";

export const client_id = process.env.REACT_APP_ClientID;


export const login = async (username, password) => {

    var bodyFormData = new FormData();
    bodyFormData.set("client_id", client_id);
    bodyFormData.append("grant_type", "password");
    bodyFormData.append("username", username);
    bodyFormData.append("password", password);

    let feedback = await httpRequestWithoutAuth( "o/token/", "POST", bodyFormData);
    return feedback
};


export const getRefreshToken = async (refresh_token) =>{
    var bodyFormData = new FormData();
    bodyFormData.set("client_id", client_id);
    bodyFormData.append("grant_type", "refresh_token");
    bodyFormData.append("refresh_token", refresh_token);

    return await httpRequestWithoutAuth( "o/token/", "POST", bodyFormData)
}