export const BaseBackEndURL = process.env.REACT_APP_BaseBackEndURL;


export const BaseClassURL = "classes/";
export const BaseClientClassesURL = BaseClassURL + "clients/";
export const BaseClassRegisterURL = BaseClientClassesURL + "register/";
export const BaseClassUnRegisterURL = BaseClientClassesURL + "unregister/";
export const BaseClassesClientFilterDataURL = BaseClassURL + "client_filter_data/";


export const BaseClientURL = "clients/";
export const BaseActivateUserURL = BaseClientURL + "activate_user/";
export const BaseClientActiveMembershipURL = `${BaseClientURL}active_membership/`;

export const BaseMyProfileURL = "users/my-profile/";

export const BaseEntityURL = "entities/";
export const BaseEntityGetBySubDirectoryURL = BaseEntityURL + "get_entity_by_sub_directory/";
export const BaseEntityGetSubDirectoryURL = `${BaseEntityURL}check-and-get-entity/`;



