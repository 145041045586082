import React from "react";
import "../../assets/scss/notfound/not_found_one.scss";

export const NotFoundOne = () => (
    <div id="notfound">
        <div className="notfound">
            <div className="notfound-404">
                <h1>Oops!</h1>
                <h2>404 - The Page can't be found</h2>
            </div>
        </div>
    </div>
);
