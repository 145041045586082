import axios from "axios";
import {BaseBackEndURL} from "../urls";

export let token = '';
export var instance;

export async function createAxiosInstance(token) {
    instance = axios.create({
        baseURL: BaseBackEndURL,
        headers: {'Authorization': 'Bearer ' + token}
    });

}


export async function httpRequestWithAuth(url, method, data, params) {
    let feedback ;
    try {

        feedback = await instance(url,
        {
            method: method,
            data,
            params
        }
    ).then(res => {
        return {status: true, msg: res.statusText, data: res.data}
    })
        .catch(error => {
            return {status: false, msg: error.response.data.non_field_errors, error_feedback: error.response.data}
        });

    }catch (e) {
        feedback =  {status: false, fatal_error:true, msg: "Something went wrong, try again", error_feedback:{}}
    }

    return feedback

}



export async function httpRequestWithoutAuth(url, method, data, params) {
    url = BaseBackEndURL + url;

    let feedback ;
    try {

        feedback = await axios(url,
            {
                method: method,
                data,
                params
            }
        ).then(res => {
            return {status: true, msg: res.statusText, data: res.data}
        })
            .catch(error => {
                return {status: false, msg: error.response.status, error_feedback: error.response.data}
            });

    }catch (e) {
        feedback =  {status: false, fatal_error:true, msg: "Something went wrong, try again", error_feedback:{}}
    }

    return feedback

}

